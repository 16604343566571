import React from 'react';


import './style.scss';


import Navbar from './navbar';

const Header = ({ siteTitle }) => (
		<Navbar />

);

export default Header;
